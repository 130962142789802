<template>
  <v-container fluid>
    <v-list-item three-line>
      <v-list-item-content>
        <p class="display-1 text--primary">
          {{ $t("menu.storage_device_type") }}
        </p>
      </v-list-item-content>
    </v-list-item>
    <v-data-table
      :headers="headers"
      :items="storagesTypesByCompany"
      class=" agTable"
      :loading="loading"
      :sort-by.sync="sortBy"
      :search="search"
      :sort-desc.sync="sortDesc"
      :no-results-text="$t('general.ui.not_search_found')"
      :no-data-text="$t('general.ui.not_data_found')"
      :loading-text="$t('general.notification.loadData')"
      :footer-props="{
        itemsPerPageText: $t('general.table.itemsPerPageText'),
        pageText: '{0}-{1} ' + $t('general.table.pageText') + ' {2}',
      }"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-row class="d-flex justify-space-between align-baseline">
            <v-col class="pl-0" lg="4" md="4" sm="6" xl="4" cols="6">
              <v-text-field onkeypress="return (event.charCode != 34)"  v-on:keyup.86="replace" v-on:keyup.ctrl.86="replace"
                dense
                filled
                rounded
                color="secondary"
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('general.titles.filter')"
                :clearable="true"
                single-line
                hide-details
              />
            </v-col>
          </v-row>
        </v-toolbar>
      </template>
      <template v-slot:item.isActive="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              small
              v-on="on"
              class="mr-4"
              color="secondary"
              v-if="item.isActive"
              @click="activeDeactivate(item)"
            >
              mdi-checkbox-marked-circle-outline
            </v-icon>
          </template>
          <span>
            {{
              $t("general.buttons.press_for") +
                " " +
                $t("general.buttons.desactivate")
            }}
            <span style="font-size: 12px; color: #AAAAAA">
              {{ $t("menu.storage_device_type") }}
            </span>
          </span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              small
              v-on="on"
              class="mr-4"
              color="#4b4b4b70"
              v-if="!item.isActive"
              @click="activeDeactivate(item)"
            >
              mdi-checkbox-marked-circle-outline
            </v-icon>
          </template>
          <span>
            {{
              $t("general.buttons.press_for") +
                " " +
                $t("general.buttons.activate")
            }}
            <span style="font-size: 12px; color: #AAAAAA">
              {{ $t("menu.storage_device_type") }}
            </span>
          </span>
        </v-tooltip>
      </template>
      <template v-slot:item.icon="{ item }">
        <v-img
          v-bind:src="item.iconBase64"
          class="mb-1 mr-1"
          max-height="30"
          min-height="30"
          max-width="30"
          min-width="30"
          eager
        />
        <!--                <v-icon size="30" class="ml-6">{{item.icon}}</v-icon>-->
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import i18n from "@/plugins/i18n";
import LanguajeService from "../../../services/LanguajeService";
import { mapActions, mapGetters } from "vuex";
import Sortable from "sortablejs";
import _ from "lodash";

export default {
  data: () => ({
    profile: null,
    editedIndex: -1,
    search: "",
    sortBy: "disposition",
    sortDesc: false,
    editedItem: {
      storage_type_name: "",
      language_key: "",
      isActive: false,
      company_name_key: [],
      icon: "mdi-alert-circle",
    },
    defaultItem: {
      icon: "mdi-alert-circle",
      isActive: false,
    },
  }),

  computed: {
    ...mapGetters({
      storagesTypesByCompany: "storage_type/storagesTypesByCompany",
      loading: "storage_type/loading",
    }),
    headers: () => [
      { text: "", value: "icon", width: 60 },
      { text: i18n.t("storageType.fields.name"), value: "storage_type_name" },
      {
        text: i18n.t("storageType.fields.active"),
        value: "isActive",
        align: "end",
      },
    ],
    formTitle() {
      return (
        (this.editedIndex === -1
          ? i18n.t("general.titles.new")
          : i18n.t("general.titles.edit")) +
        " " +
        i18n.t("menu.storage_device_type")
      );
    },
  },
  created() {
    this.initialize();
  },
  mounted() {
    let table = document.querySelector(".v-data-table tbody");
    const _self = this;
    Sortable.create(table, {
      onEnd({ newIndex, oldIndex }) {
        let storageTypes = Object.assign(
          [],
          _.orderBy(_self.storagesTypesByCompany, "disposition")
        );
        const rowSelected = storageTypes.splice(oldIndex, 1)[0];
        storageTypes.splice(newIndex, 0, rowSelected);
        storageTypes = _.map(storageTypes, (item, key) => {
          return {
            ...item,
            disposition: key + 1,
          };
        });
        let items = { sdtList: storageTypes };
        _self.changePosition(items);
      },
    });
  },

  methods: {
    ...mapActions({
      fetchStorageTypesByCompany: "storage_type/fetchAllStorageTypesByCompany",
      fetchStorageTypesActiveByCompany:
        "storage_type/fetchStorageTypesActiveByCompany",
      changePositions: "storage_type/changePositions",
      activateDeactivateStorageTypes:
        "storage_type/activateDeactivateStorageTypes",
      languajeKeys: "general/getLanguajeKeys",
    }),
    initialize() {
      this.profile = JSON.parse(localStorage.getItem("profile"));
      // this.fetchStorageTypesByCompany([this.profile.company_id, this.$toast]);
    },
    getItem(item) {
      this.editedIndex = this.storagesTypesByCompany.indexOf(item);
      this.editedItem = Object.assign({}, item);
    },
    activeDeactivate(item) {
      this.getItem(item);
      this.editedItem.isActive = !this.editedItem.isActive;
      this.save();
    },
    close() {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    },
    changePosition(storageTypes) {
      this.changePositions([storageTypes, this.$toast]).finally(() => {
        this.fetchStorageTypesByCompany([this.profile.company_id, this.$toast]);
        this.fetchStorageTypesActiveByCompany([this.$toast]);
      });
    },
    save() {
      let item = Object.assign({}, this.editedItem);
      item.language_key = item.id
        ? item.language_key
        : item.storage_type_name.replace(/ /g, "_").toLowerCase();
      item.company_id = this.profile.company_id;

      this.activateDeactivateStorageTypes([
        item,
        this.editedIndex,
        this.$toast,
      ]).finally(() => {
        this.fetchStorageTypesByCompany([this.profile.company_id, this.$toast]);
        this.fetchStorageTypesActiveByCompany([this.$toast]);
      });
    },
    replace(event){
      event.currentTarget.value =  event.currentTarget.value.toString().replaceAll('"','')
      this.search =  event.currentTarget.value.toString().replaceAll('"','')
    }
  },
};
</script>

<style scoped></style>
